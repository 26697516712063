import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { Scoped } from "kremling";
import { property } from "lodash";
import "./set-public-path.js";

// @ts-ignore
Scoped.defaultNamespace = "calendar-ui";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(/* webpackChunkName: "calendar-app" */ "./root.component.js").then(property("default")),
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  setPublicPath(publicPath: string) {
    // @ts-ignore
    __webpack_public_path__ = publicPath;
  },
  hotload: {
    module,
    dev: {
      enabled: true,
    },
  },
  overlay: {
    selectors: ["#calendar-ui .cps-topnav-secondary"],
  },
});

export const bootstrap = [canopyLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

export const devtools = {
  overlays: {
    selectors: ["#calendar-ui .cps-topnav-secondary", "#calendar-ui .container", "#calendar-ui .positioned-view"],
    options: {
      textBlocks: ["blue squad"],
    },
  },
};

// TODO: remove after contacts-ui has been updated to getClientCalendarCard
export function getContactCalendarCard() {
  return import(
    /* webpackChunkName: "contact-calendar-card" */ "./calendar/views/client/client-card.component.js"
  ).then((mod) => mod);
}

export function getClientCalendarCard() {
  return import(/* webpackChunkName: "client-calendar-card" */ "./calendar/views/client/client-card.component.js").then(
    (mod) => mod
  );
}

export function getCreateEvent() {
  return import(
    /* webpackChunkName: "create-event" */ "./calendar/create-edit/event-details/event-details-export.component.js"
  );
}

function domElementGetter() {
  let el = document.getElementById("calendar-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "calendar-ui");
    document.body.appendChild(el);
  }
  return el;
}
